import { Button, Checkbox, Form, Input, Spin, Typography } from 'antd';
import { InfoBullets, RegistratieJaar } from './components';
import { Relatie } from '@/lib/types';
import { useFeature } from '@/lib/hooks/useFeature';
import { Feature } from '@/lib/components/core/features/feature';
import Title from 'antd/es/typography/Title';

export const Step1: React.FunctionComponent<{ onSubmit: () => void; isLoading: boolean; isError: boolean; }> = ({ onSubmit, isLoading, isError }) => {
  const form = Form.useFormInstance<Relatie>();
  const isVolledig = form.getFieldValue('isVolledig');
  const registratiejaar = form.getFieldValue('registratiejaar');

  const reportIsVolledig = isVolledig === true && !isError && !isLoading;
  const reportIsOnvolledig = isVolledig === false && !isError && !isLoading;
  const reportIsEmpty = isVolledig === null && !isError && !isLoading;
  const redenVanAfkeuring = form.getFieldValue('redenVanAfkeuring')

  const isEnabled = useFeature('show-editrapportage-button');

  return (
    <>
      <InfoBullets />
      <Title style={{ margin: '10px 0px' }} level={5}>
        Voor welk uo-nummer is de rapportage?
      </Title>
      <Form.Item rules={[{ required: true, message: '' }]} style={{ width: '30%', paddingTop: '10px' }} name={'uoNummer'}>
        <Input maxLength={10} placeholder='Vul uo-nummer in' />
      </Form.Item>
      <RegistratieJaar isLoading={isLoading} />
      {isLoading && (
        <Spin />
      )}
      {reportIsVolledig && (
        <>
          <Typography>Er is al een jaarrapportage ingevoerd over dit jaar en deze is definitief. Wijziging is niet mogelijk.</Typography>
        </>
      )}
      {(reportIsEmpty || reportIsOnvolledig) && (
        <>
          {reportIsOnvolledig && (
            <>
              <Typography style={{ 'color': 'red' }}>Ingediende rapportage is als onvolledig verklaard. Pas uw rapportage aan en dien opnieuw in.</Typography>
              {redenVanAfkeuring && (
                <Typography>Reden van afkeuring: {redenVanAfkeuring}</Typography>
              )}
            </>
          )}
          <Feature flag="show-deelrapportage-button">
            <Form.Item name={['isDeelRapportage']} valuePropName="checked" initialValue={false} style={{ marginBottom: 0, marginTop: 10 }}>
              <Checkbox>Is deelrapportage</Checkbox>
            </Form.Item>
          </Feature>
          <Button
            loading={isLoading}
            disabled={!registratiejaar || isLoading || isError || (reportIsOnvolledig && !isEnabled)}
            onClick={onSubmit}
            type="primary"
          >
            {reportIsEmpty ?
              <>
                Nieuwe rapportage invullen
              </> :
              <>
                Rapportage wijzigen
              </>}
          </Button>

        </>
      )}
    </>
  );
}