import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchAuthenticatedForJson } from '../utils';
import { Afzender, Relatie } from '../types/relatie';
import { Pand, Verblijfsobject } from '../types/bag';
import { fetchBagPanden, fetchBagVerblijfsobjecten } from '../utils/bagApi';
import { LatLngBounds } from 'leaflet';

const QUERY_KEY = ['input'];
const BASE_URL = import.meta.env.VITE_INPUT_API_URL;
const BAG_WFS_URL = import.meta.env.VITE_INPUT_API_BAG_URL;

export const useGetHandmatigeInvoer = (year?: string, uonummer?: string): UseQueryResult<Relatie> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'rapportage', year, uonummer],
    queryFn: () => fetchAuthenticatedForJson<Relatie>(`${BASE_URL}/input?year=${year}&uonummer=${uonummer}`),
    enabled: year != undefined && uonummer != undefined,
    retry: false,
    staleTime: 0
  });

export const useGetDeskundige = (): UseQueryResult<{ label: string; value: string }[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'deskundige'],
    queryFn: () => fetchAuthenticatedForJson<{ label: string; value: string }[]>(`${BASE_URL}/input/deskundige`),
  });
export const useGetSectoren = (): UseQueryResult<{ code: string; omschrijving: string }[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'sectoren'],
    queryFn: () => fetchAuthenticatedForJson<{ code: string; omschrijving: string }[]>(`${BASE_URL}/input/sectoren`),
  });

export const useGetGewassen = (): UseQueryResult<{ code: string; omschrijving: string; sector: { code: string; omschrijving: string } }[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'gewassen'],
    queryFn: () => fetchAuthenticatedForJson<{ code: string; omschrijving: string; sector: { code: string; omschrijving: string; } }[]>(`${BASE_URL}/input/gewassen`),
  });

export const useGetPanden = (bounds: LatLngBounds | null, enabled: boolean): UseQueryResult<Pand[]> => {
  return useQuery({
    queryKey: ['panden', bounds],
    queryFn: () => (bounds && enabled ? fetchBagPanden(`${BAG_WFS_URL}&TYPENAME=bag:pand`, bounds) : Promise.resolve([])),
    staleTime: 60 * 1000,
    enabled: !!bounds && enabled,
    placeholderData: (previousData) => previousData
  });
}

export const useGetVerblijfsobjecten = (bounds: LatLngBounds | null, enabled: boolean): UseQueryResult<Verblijfsobject[]> => {
  return useQuery({
    queryKey: ['verblijfsobjecten', bounds],
    queryFn: () => (bounds ? fetchBagVerblijfsobjecten(`${BAG_WFS_URL}&TYPENAME=bag:verblijfsobject`, bounds) : Promise.resolve([])),
    staleTime: 60 * 1000,
    enabled: !!bounds && enabled,
    placeholderData: (previousData) => previousData
  });
}

export const useSendHandmatigeInvoer = (): UseMutationResult<Afzender, Error, Afzender> => {
  return useMutation<Afzender, Error, Afzender>({
    mutationFn: (newManualInput: Afzender) =>
      fetchAuthenticatedForJson<Afzender>(`${BASE_URL}/input`, {
        json: newManualInput,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useValidate = (): UseMutationResult<Afzender, Error, Afzender> => {
  return useMutation<Afzender, Error, Afzender>({
    mutationFn: (newManualInput: Afzender) =>
      fetchAuthenticatedForJson<Afzender>(`${BASE_URL}/input/validate`, {
        json: newManualInput,
        method: 'PUT',
      }),
    mutationKey: [...QUERY_KEY],
  });
};