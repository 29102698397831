import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React, { JSX } from 'react';
import { ErrorComponent } from '../components/core/errorComponent';
import { loginRequest } from '../utils/msal';

export const RequireLogin = ({ children }: React.PropsWithChildren): JSX.Element => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={ErrorComponent} authenticationRequest={loginRequest}>
      {children}
    </MsalAuthenticationTemplate>
  );
};