import { Button, Card, Divider, Modal, Row, Skeleton, Spin, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteArtikel, useGetArtikels } from "@/lib/queries/content";
import { Roles, tokenContainsRole } from "@/lib/context";
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/nl";
dayjs.extend(relativeTime);

export const ArticleList: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const showEditButton = tokenContainsRole([Roles.Administrator]) && location.pathname.includes("/content-management");
  const { data: artikels, isLoading, refetch } = useGetArtikels();
  const { mutate: deleteArtikel } = useDeleteArtikel();
  const sortedArtikels = artikels?.slice().sort((a, b) => dayjs(b.datumToegevoegd).isAfter(dayjs(a.datumToegevoegd)) ? 1 : -1);
  const handleDelete = (id: number) => {
    Modal.confirm({
      title: "Weet je zeker dat je dit artikel wilt verwijderen?",
      content: "Deze actie kan niet ongedaan worden gemaakt.",
      okText: "Ja, verwijderen",
      okType: "danger",
      cancelText: "Annuleren",
      onOk: () => {
        deleteArtikel(id, {
          onSuccess: () => {
            refetch();
          }
        });
      },
    });
  };

  return (
    <>
      {isLoading && (
        <div style={{
          position: "relative",
          width: "100%",
          height: "5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Spin size="large" />
        </div>
      )}
      {sortedArtikels?.map((artikel, index) => (
        <div
          key={index}
          style={{
            width: "800px",
            margin: "auto",
            marginBottom: '20px',
            position: "relative"
          }}
        >
          <Card
            title={<Typography.Title level={2}>{artikel.titel}</Typography.Title>}
            extra={showEditButton && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button onClick={() => navigate(`/article/edit/${artikel.id}`)}>Bijwerken</Button>
                <Button onClick={() => handleDelete(artikel.id)} danger>Verwijderen</Button>
              </div>
            )}
          >
            <ReactQuill
              value={artikel.body}
              readOnly={true}
              modules={{
                toolbar: false,
              }}
              style={{
                border: "none"
              }}
            />
            {/* Pas gebruiken wanneer klant erom vraagt */}
            {/* <Divider />
            <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
              {dayjs(artikel.datumToegevoegd).format("DD MMM YYYY")}
            </Typography.Text> */}
          </Card>
        </div>
      ))}
    </>
  );
};
