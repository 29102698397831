import { Page } from '@/lib/components/core/pageItems/page';
import { useGetHandmatigeInvoer, useSendHandmatigeInvoer, useValidate } from '@/lib/queries/manual_input';
import { Form, Space, Steps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { StepProps } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Step1 } from './step_1/page';
import { Step2 } from './step_2/page';
import { Step3 } from './step_3/page';
import { Step4 } from './step_4/page';
import { Step5 } from './step_5/page';
import { Afzender, RapportageType, Relatie } from '@/lib/types/relatie';
import { v4 as uuid } from 'uuid'
import { useMsal } from '@azure/msal-react';
import ResponseModal from './modals/responsemodal';
import { useNavigate } from 'react-router-dom';

export const ManualInputPage: React.FunctionComponent = () => {
  const { t } = useTranslation('input');
  const [form] = useForm<Relatie>();
  const navigate = useNavigate();

  const registratieJaar = Form.useWatch('registratiejaar', form);
  const uonummer = Form.useWatch('uoNummer', form);

  const [currentStep, setCurrentStep] = useState(0);
  const [responseContent, setResponseContent] = useState<string | null>(null);
  const [modalErrorContent, setModalErrorContent] = useState<string | null>(null);

  const [formAfzender, setFormAfzender] = useState<Afzender>({} as Afzender);

  const { instance: msalInstance } = useMsal();
  const { mutate: mutateHandmatigeInvoer, isPending: isSaving } = useSendHandmatigeInvoer();
  const { mutate: validate, isPending: isValidating } = useValidate();
  const { data: handmatigeInvoerData, isLoading: handmatigeInvoerIsLoading, isError: handmatigeInvoerIsError } = useGetHandmatigeInvoer(registratieJaar, uonummer);

  useEffect(() => {
    if (handmatigeInvoerData) {
      form.setFieldsValue(handmatigeInvoerData);
    }
  }, [handmatigeInvoerData, form]);

  const [items, setItems] = useState<StepProps[]>([
    {
      status: 'process',
      title: 'Introductie',
      disabled: false,
    },
    {
      status: 'wait',
      title: 'Bedrijfsgegevens',
      disabled: true,
    },
    {
      status: 'wait',
      title: 'Milieugegevens',
      disabled: true,
    },
    {
      status: 'wait',
      title: 'Teeltplan',
      disabled: true,
    },
    {
      status: 'wait',
      title: 'Versturen',
      disabled: true,
    },
  ]);

  const updateStep = (page: number) => {
    setItems(current => {
      const updated = [...current];
      updated[page].status = 'finish';
      updated[page].disabled = page === 0

      if (updated[page + 1].status !== 'finish') {
        updated[page + 1].status = 'process';
        updated[page + 1].disabled = false
      }
      setCurrentStep(currentStep + 1)
      return updated;
    });
  };

  function updateAfzender(currentValues, milieuValidatie, teeltplanRegelsValidatie) {
    setFormAfzender(prev => {
      const afzender = {
        ...prev,
        isMilieugegevensValidatie: milieuValidatie,
        isTeeltplanRegelsValidatie: teeltplanRegelsValidatie,
        relatie: [{
          ...prev.relatie[0],
          ...currentValues
        }]
      }
      if (currentStep === 2 || currentStep === 3 || currentStep === 4) {
        validateAfzender(afzender)
      }
      return afzender;
    })
  }

  function validateAfzender(afzender) {
    validate(afzender, {
      onSuccess: (response: any) => {
        //Check if response is OK result
        const isSuccess = response?.relaties?.some(relatie => relatie?.foutBerichten?.some(foutBericht => foutBericht?.code === 0));

        //Check if there are warnings
        const containsWarnings = response?.relaties?.some(relatie => relatie?.foutBerichten?.some(foutBericht => foutBericht?.code != 0));

        if (isSuccess && currentStep !== 4) {
          if (containsWarnings) {
            //Only show warnings, not the OK result
            response.relaties[0].foutBerichten = response?.relaties[0].foutBerichten?.filter(foutBericht => foutBericht?.code !== 0);
            setResponseContent(JSON.stringify(response, null, 2));
          }
          else {
            updateStep(currentStep);
          }
        } else {
          setResponseContent(JSON.stringify(response, null, 2));
        }
      },
      onError: error => {
        setResponseContent(error.message);
        setModalErrorContent(
          'Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.'
        );
      },
    });
  }

  const onSubmitStep1 = async () => {
    form
      .validateFields()
      .then(() => {
        const account = msalInstance.getActiveAccount();
        const username = account?.username || 'unknown';
        const currentValues = form.getFieldsValue();
        setFormAfzender(prev => {
          return {
            ...prev,
            berichtVersie: '03.00',
            berichtID: uuid(),
            timestamp: new Date().toISOString(),
            isDeelRapportage: currentValues["isDeelRapportage"] ? 1 : 2,
            isUpdate: true,
            loginNaam: username.toString(),
            relatie: [{
              registratiejaar: currentValues["registratiejaar"]
            }] as Relatie[]
          }
        })
        updateStep(currentStep);
      })
  }

  const onSubmitStep2 = async () => {
    form
      .validateFields()
      .then(() => {
        const currentRelatie = form.getFieldsValue();
        updateAfzender(currentRelatie, false, false);
        updateStep(currentStep);
      })
  }

  const onSubmitStep3 = async () => {
    try {
      form.validateFields()
        .then(() => {
          const sanitizedRelatie = sanitize(form.getFieldsValue());
          updateAfzender(sanitizedRelatie, true, false);
        })
    } catch (e) {
      setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.');
    }
  };

  const onSubmitStep4 = async () => {
    try {
      form.validateFields()
        .then(() => {
          const sanitizedRelatie = sanitize(form.getFieldsValue());
          updateAfzender(sanitizedRelatie, false, true);
        })
    } catch (e) {
      setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.');
    }
  }

  const onSubmitStep5 = async () => {
    form
      .validateFields()
      .then(() => {
        const currentRelatie = form.getFieldsValue();
        updateAfzender(currentRelatie, true, false);
        form.submit();
      })
  }

  function sanitize(relatie: Relatie): Relatie {
    const updatedRelatie = {
      ...relatie,
      ...sanitizeLozingsVorm(relatie),
    };

    return {
      ...updatedRelatie,
      ...sanitizeMetingen(updatedRelatie),
      ...sanitizeLozingen(updatedRelatie),
    };
  }

  function sanitizeLozingen(relatie: Relatie) {
    let lozingen = relatie.lozingen?.filter(l => l !== undefined && l !== null);

    if (lozingen === null || lozingen === undefined) {
      return;
    }

    relatie.lozingsVorm.forEach(element => {
      if (element.value == 0 || element.value == 4) {
        lozingen = relatie.lozingen.filter(m => m.betrekkingTotTeeltwijze !== element.betrekkingTotTeeltwijze)
        let alleLozingen = Array.from({ length: 13 }, (_, index) => ({
          periode: index + 1,
          hoeveelheid: 0,
        }))
        relatie.lozingen.push({
          betrekkingTotTeeltwijze: element.betrekkingTotTeeltwijze,
          lozingen: alleLozingen
        })
      }
    });
    return {
      lozingen: lozingen
    }
  }

  function sanitizeLozingsVorm(relatie: Relatie) {
    let lozingsVormen = relatie.lozingsVorm?.filter(l => l !== undefined && l !== null);

    if (lozingsVormen === null || lozingsVormen === undefined) {
      return;
    }

    return {
      lozingsVorm: lozingsVormen
    }
  }

  function sanitizeMetingen(relatie: Relatie) {
    let metingen = relatie.metingen?.filter(m => m !== undefined && m !== null);
    if (metingen === null || metingen === undefined) {
      return;
    }
    for (let metingenIndex = 0; metingenIndex < metingen.length; metingenIndex++) {
      let meting = metingen[metingenIndex];
      for (let metingIndex = 0; metingIndex < meting.nH4Metingen.length; metingIndex++) {
        let nh4Meting = meting.nH4Metingen[metingIndex];
        if (meting.nO3Metingen[metingIndex] !== null) {
          meting.nO3Metingen[metingIndex].datum = nh4Meting.datum
        }
        if (meting.pMetingen[metingIndex] !== null) {
          meting.pMetingen[metingIndex].datum = nh4Meting.datum
        }
      }
      meting.nH4Metingen = meting.nH4Metingen.filter((meting) => meting.datum !== null && meting.gehalte !== null && meting.gehalte !== undefined);
      meting.nO3Metingen = meting.nO3Metingen.filter((meting) => meting.datum !== null && meting.gehalte !== null && meting.gehalte !== undefined);
      meting.pMetingen = meting.pMetingen.filter((meting) => meting.datum !== null && meting.gehalte !== null && meting.gehalte !== undefined);
    }
    relatie.lozingsVorm.forEach(element => {
      if (element.value == 0 || element.value == 4) {
        metingen = metingen.filter(m => m.betrekkingTotTeeltwijze !== element.betrekkingTotTeeltwijze)
        metingen.push({
          betrekkingTotTeeltwijze: element.betrekkingTotTeeltwijze,
          aantalMetingenPerJaar: 0,
          isBerekend: false,
          nH4Metingen: [],
          nO3Metingen: [],
          pMetingen: []
        })
      }
    });

    return {
      metingen: metingen
    }
  }

  const onModalOk = () => {
    setResponseContent(null);
    setModalErrorContent(null);

    let responseData = JSON.parse(responseContent || '');

    if (currentStep === 4) {
      if (responseData?.relaties?.some((relatie) => relatie?.foutBerichten?.some((foutBericht) => foutBericht?.code === 0 || foutBericht?.code === 4))) {
        navigate('/')
        return;
      }
    } else {
      if (responseData?.relaties?.some((relatie) => relatie?.foutBerichten?.some((foutBericht) => foutBericht?.code === 4))) {
        updateStep(currentStep)
      }
    }

  }


  const onCompleteFormSubmit = async () => {

    try {
      mutateHandmatigeInvoer(formAfzender, {
        onSuccess: (response) => {
          setResponseContent(JSON.stringify(response, null, 2));
        },
        onError: (response) => {
          setResponseContent(response.message);
          setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.')
        },
      });
    } catch (e) {
      setModalErrorContent('Er is een onverwachte fout opgetreden. Neem contact op met de servicedesk.')
    }
  }

  const steps = [
    <Step1 onSubmit={onSubmitStep1} isLoading={handmatigeInvoerIsLoading} isError={handmatigeInvoerIsError} />,
    <Step2 onSubmit={onSubmitStep2} />,
    <Step3 onSubmit={onSubmitStep3} isValidating={isValidating} />,
    <Step4 onSubmit={onSubmitStep4} isValidating={isValidating} />,
    <Step5 onSubmit={onSubmitStep5} isSaving={isSaving} />,
  ];
  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical">
        <Steps
          style={{ marginBottom: '20px', maxWidth: '1000px', margin: '0 auto' }}
          type="navigation"
          size="small"
          current={currentStep}
          onChange={setCurrentStep}
          className="site-navigation-steps"
          items={items}
        />
        <Page title={t(`form.title.${currentStep}`)}>
          <Form onFinish={onCompleteFormSubmit} form={form}>
            {steps[currentStep]}
          </Form>
        </Page>

        <ResponseModal
          responseContent={responseContent}
          error={modalErrorContent}
          onOk={onModalOk}
        />
      </Space>
    </>
  );
};