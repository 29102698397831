import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, MenuProps, Space } from 'antd';
import React from 'react';
import styles from './auth.module.scss';

interface UserAvatarProps {
  user?: string;
  items: MenuProps['items'];
}

export const UserAvatar: React.FunctionComponent<UserAvatarProps> = ({ user, items }) => {
  return (
    <Dropdown menu={{ items }}> 
      <Space>
        <Avatar size={32} className={styles.avatar} icon={<UserOutlined />} />
        <Space.Compact block style={{ lineHeight: 'normal' }} direction="vertical">
          <span>{user}</span>
        </Space.Compact>
      </Space>
    </Dropdown>
  );
};
