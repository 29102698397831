import ReactQuill from 'react-quill-new';
import './styles.modules.scss'

interface CustomRichTextProps {
  value?: string;
  onChange?: (content: string) => void;
}

export const CustomRichText: React.FunctionComponent<CustomRichTextProps> = ({ value, onChange }) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={{
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }],
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['bold', 'italic', 'underline'],
          ['link', 'image'],
          ['blockquote', 'code-block'],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          ['clean'],
        ],
      }}
      formats={[
        'header', 'font', 'size', 'list', 'align', 'bold', 'italic', 'underline',
        'link', 'image', 'blockquote', 'code-block', 'indent', 'direction', 'clean',
      ]}
      style={{ width: '800px', height: 'auto', marginBottom: '20px', backgroundColor: 'white', fontStyle: 'Times New Roman' }}
    />
  );
};
