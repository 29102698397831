import { Page } from "@/lib/components/core/pageItems/page";
import { useGetArtikelById, useUpdateArtikel } from "@/lib/queries/content";
import { Artikel } from "@/lib/types/content";
import { Button, Col, Divider, Form, Input, Spin, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomRichText } from "@/lib/components/core/custom-richtext/CustomRichText";

export const UpdateArticlePage: React.FunctionComponent = () => {
  const { Title } = Typography;
  const [form] = useForm<Artikel>();
  const navigate = useNavigate();
  const id = Number(useParams().id?.toString());
  const { mutateAsync: updateArtikel } = useUpdateArtikel(id);
  const { data: artikelById } = useGetArtikelById(id);

  const handleFinish = async (values: Artikel) => {
    try {
      await updateArtikel(values);
      navigate("/content-management")
    } catch (error) {
      console.error("Error creating article:", error);
    }
  };

  useEffect(() => {
    if (artikelById) {
      form.setFieldsValue({
        titel: artikelById.titel,
        body: artikelById.body,
      });
    }
  }, [artikelById, form]);

  return (
    <>
      <Page title={'Nieuws bijwerken'}>
        <Form form={form} onFinish={handleFinish}>
          <Divider />
          <Title style={{ margin: '10px 0px' }} level={5}>
            Titel
          </Title>

          <Col span={4}>
            <Form.Item name='titel' initialValue={artikelById?.titel} rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
          </Col>

          <Title style={{ margin: '10px 0px' }} level={5}>
            Inhoud
          </Title>
          <Form.Item
            name='body'
            rules={[{ required: true, message: 'Inhoud is verplicht' }]}
            initialValue={artikelById?.body}
          >
            <CustomRichText />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Opslaan
          </Button>

        </Form>
      </Page>
    </>
  );
};