import { CustomRichText } from "@/lib/components/core/custom-richtext/CustomRichText";
import { Page } from "@/lib/components/core/pageItems/page";
import { useCreateArtikel } from "@/lib/queries/content";
import { Artikel } from "@/lib/types/content";
import { Button, Col, Divider, Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";

export const CreateArticlePage: React.FunctionComponent = () => {
  const { Title } = Typography;
  const [form] = useForm<Artikel>();
  const navigate = useNavigate();

  const { mutateAsync: createArtikel } = useCreateArtikel();

  const handleFinish = async (values: Artikel) => {
    try {
      await createArtikel(values);
      navigate("/content-management")
    } catch (error) {
      console.error("Error creating article:", error);
    }
  };

  return (
    <>
      <Page title={'Nieuws toevoegen'}>
        <Form form={form} onFinish={handleFinish}>
          <Divider />
          <Title style={{ margin: '10px 0px' }} level={5}>
            Titel
          </Title>

          <Col span={4}>
            <Form.Item name='titel' rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
          </Col>

          <Title style={{ margin: '10px 0px' }} level={5}>
            Inhoud
          </Title>
          <Form.Item name='body'>
            <CustomRichText />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Invoeren
          </Button>

        </Form>
      </Page>
    </>
  );
};