import { PageWidget } from '@/lib';
import { Page } from '@/lib/components/core/pageItems/page';
import { DownloadEnvReportButton } from '@/lib/components/reports/downloadEnvReportButton';
import { ReviewEnvReportButton } from '@/lib/components/reports/reviewEnvReportButton';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { Roles, tokenContainsRole } from '@/lib/context';
import { useGetEnvironmentReports } from '@/lib/queries/reports';
import { BasicTableColumn, PagedResponse, PaginationQueryParameters, Review, ReviewStatus } from '@/lib/types';
import { TeeltplanIndex } from '@/lib/types/domain';
import { Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EnvironmentReportsPage: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');
  const updateHook = useRef(() => { });

  const showReviewButton = tokenContainsRole([Roles.Administrator, Roles.Bevoegdgezag])
  const showDownloadReportButton = tokenContainsRole([Roles.Administrator, Roles.Bevoegdgezag, Roles.Deelnemer, Roles.Dienstverlener, Roles.Toezichthouder])

  const columns: BasicTableColumn<TeeltplanIndex>[] = [
    { label: "UO Nummer", dataIndex: 'deelnemer.uoNummer', sortable: false, filterable: true, width: 1 },
    { label: "Bedrijfsnaam", dataIndex: 'deelnemer.bedrijfsNaam', sortable: false, filterable: true },
    { label: "Straat", dataIndex: 'deelnemer.straat', sortable: false, filterable: true },
    {
      label: "Huisnr", dataIndex: 'deelnemer.huisNummer', sortable: false, filterable: true, width: 1, render: (item, record) => {
        return item + (!!record?.deelnemer.huisNummerToevoeging ? " " + record?.deelnemer.huisNummerToevoeging : "")
      }
    },
    { label: "Plaats", dataIndex: 'deelnemer.plaats', sortable: false, filterable: true },
    { label: "Registratiejaar", dataIndex: 'registratieJaar', sortable: false, filterable: true },
    {
      label: "Keuring",
      dataIndex: 'review',
      render: (review, record) => {
        return <Tooltip title={review?.reason}>
          {review === null || undefined || review.status == ReviewStatus.Onbekend ? 'Nog niet gekeurd' : (ReviewStatus[review.status])}
        </Tooltip>
      }
    },
    {
      key: 'actions',
      dataIndex: 'id',
      label: '',
      render: (item, record) => (<>
        {showDownloadReportButton && (
          <DownloadEnvReportButton reportData={record} />
        )}
        {showReviewButton && (
          <ReviewEnvReportButton reportData={record} onOk={updateHook.current} />
        )}
      </>),
    }
  ];

  return (
    <Page title={t('pages.environmentReports.title')}>
      <PageWidget>
        <BasicTable<TeeltplanIndex>
          loadEntries={useGetEnvironmentReports} columns={columns} updateHook={updateHook}
        />
      </PageWidget>
    </Page>
  );
};