import { Feature } from '@/lib/components/core/features/feature';
import { MaintenanceWidget } from '@/lib/components/core/widgets/maintenanceWidget';
import React from 'react';
import styles from './page.module.scss';
import { ArticleList } from './content/article-list/page';
import { useAccount, useIsAuthenticated } from '@azure/msal-react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Roles, tokenContainsRole } from '@/lib/context';
import { Page } from '@/lib/components/core/pageItems/page';
export const Home: React.FunctionComponent = () => {
  const isAuthenticated = useAccount();
  const navigate = useNavigate();
  const showEditButton = tokenContainsRole([Roles.Administrator]);

  return (
    <>
      {!isAuthenticated && (
        <div className={styles['s-home-intro']}>
          <Feature flag="show-maintenance-mode">
            {!isAuthenticated && (
              <MaintenanceWidget />
            )}
          </Feature>
        </div>
      )}
      {showEditButton && (
        <div style={{ textAlign: "center", padding: "1rem" }}>
          <Button onClick={() => navigate(`/content-management`)}>Nieuwsartikelen beheren</Button>
        </div>
      )}
      <ArticleList />

    </>
  );
};
