import { FC, Suspense } from 'react';

import { App } from 'antd';
import { ErrorBoundary } from './lib';
import { Providers } from './providers';
import { AppRouter } from './router';

import '@ant-design/v5-patch-for-react-19';

export const FrontOfficeApp: FC = () => (
  <Suspense fallback={<p>loading</p>}>
    <App>
      <Providers>
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
      </Providers>
    </App>
  </Suspense>
);