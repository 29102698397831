import { Card, Divider, message, Spin, Table } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useCreateHandleiding, useGetAll } from "@/lib/queries/content";
import Dragger from "antd/es/upload/Dragger";

export const GuideManagementPage: React.FunctionComponent = () => {
  const { mutate } = useCreateHandleiding();
  const { data: pdfList, isLoading, refetch } = useGetAll();

  const columns = [
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Datum toegevoegd',
      key: 'datum',
      dataIndex: 'datum',
      render: (datum: string) => new Date(datum).toLocaleString()
    }
  ]

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: (file: File) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        let base64 = reader.result as string;
        base64 = base64.split(",")[1];
        const newHandleiding = {
          name: file.name,
          file: base64,
          datum: new Date()
        }
        try {
          mutate(newHandleiding, {
            onSuccess: () => {
              refetch();
            }
          });
          message.success(`${file.name} uploaded successfully`)
        }
        catch (error) {
          message.error('Failed to upload')
        }
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Dragger {...props} fileList={[]} disabled={isLoading}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Klik of sleep PDF naar dit gebied om te uploaden</p>
        </Dragger>
      </div>
      <Divider />
      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table dataSource={pdfList} columns={columns} />
      )}
    </>
  );
};
