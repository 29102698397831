import { Relatie } from '@/lib/types';
import { Typography, Form, Select, Radio, Space } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Waterlozing: FC = () => {
  const { t } = useTranslation('input');
  const { Title } = Typography;
  const form = Form.useFormInstance<Relatie>();

  const zandfilterDrainage = Form.useWatch(['controle', 'zandfilterDrainage'], form);
  const incidenteleLozing = Form.useWatch(['controle', 'incidenteleLozing'], form);
  const calamiteit = Form.useWatch(['controle', 'calamiteit'], form);
  const waterOverschot = Form.useWatch(['controle', 'waterOverschot'], form);
  const drainageLozing = Form.useWatch(['controle', 'drainageLozing'], form);

  const geenNullozing = [
    zandfilterDrainage,
    incidenteleLozing,
    calamiteit,
    waterOverschot,
    drainageLozing,
  ].some(bool => bool === true);

  const teeltwijzeValue = Form.useWatch('teeltwijze', form);
  const isGrondteelt = [0].includes(teeltwijzeValue);
  const isSubstraatteelt = [1].includes(teeltwijzeValue);
  const isCombiteelt = [2].includes(teeltwijzeValue);

  return (
    <>
      <Title style={{ margin: '10px 0px' }} level={5}>
        {t('form.waterdischarge.title')}
      </Title>
      <Typography>
        {isGrondteelt && t('form.waterdischarge.topTextGrondteelt')}
        {isSubstraatteelt && t('form.waterdischarge.topTextSubstraatteelt')}
        {isCombiteelt && t('form.waterdischarge.topTextCombiteelt')}
      </Typography>
      <Form.Item
        name="waterLozingSituatie"
        rules={[{ required: true, message: t('form.waterdischarge.requiredMessage') }]}
      >
        <Radio.Group >
          <Space size={'middle'} direction="vertical">
            <Radio value={8}>{t('form.waterdischarge.option_1')}</Radio>
            <Radio value={6}>{t('form.waterdischarge.option_2')}</Radio>
            <Radio value={7}>{t('form.waterdischarge.option_3')}</Radio>
            <Radio value={1}>{t('form.waterdischarge.option_4')}</Radio>
            <Radio value={2}>
              {isGrondteelt && t('form.waterdischarge.option_5_grondteelt')}
              {isSubstraatteelt && t('form.waterdischarge.option_5_substraatteelt')}
              {isCombiteelt && t('form.waterdischarge.option_5_combiteelt')}
            </Radio>
            <Radio value={9}>
              {isGrondteelt && t('form.waterdischarge.option_6_grondteelt')}
              {isSubstraatteelt && t('form.waterdischarge.option_6_substraatteelt')}
              {isCombiteelt && t('form.waterdischarge.option_6_combiteelt')}
            </Radio>
            <Radio value={4}>
              {isGrondteelt && t('form.waterdischarge.option_7_grondteelt')}
              {isSubstraatteelt && t('form.waterdischarge.option_7_substraatteelt')}
              {isCombiteelt && t('form.waterdischarge.option_7_combiteelt')}
            </Radio>
            <Radio value={5} disabled={geenNullozing}>
              {t('form.waterdischarge.option_8')}
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};