import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ArticleManagementPage } from "./_tabs/articles";
import { GuideManagementPage } from "./_tabs/guides";
import { Page } from "@/lib/components/core/pageItems/page";
import { useFeature } from "@/lib/hooks/useFeature";

export const ContentManagementPage: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isEnabled = useFeature('show-handleiding-button');

  const getDefaultActiveTab = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('tab') || 'article';
  };

  const [activeTab, setActiveTab] = useState(getDefaultActiveTab());

  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`/content-management?tab=${key}`);
  };

  const items = [
    {
      label: 'Nieuwsartikelen',
      key: 'article',
      children: <ArticleManagementPage key='article' />,
    },
    ...(isEnabled
      ? [
        {
          label: 'Handleiding',
          key: 'guide',
          children: <GuideManagementPage key='guide' />,
        },
      ]
      : []),
  ];

  return (
    <Page title={'Content beheer'}>
      <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
    </Page>
  )
};