import { Page } from "@/lib/components/core/pageItems/page";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ArticleList } from "../../article-list/page";

export const ArticleManagementPage: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "1rem" }}>
        <Button onClick={() => navigate('/article/create')}>Nieuwsartikel toevoegen</Button>
      </div>
      <ArticleList />
    </>
  );
};