export interface Afzender {
  berichtVersie: string,
  berichtID: string,
  timestamp: string,
  loginNaam: string,
  isUpdate: boolean,
  isDeelRapportage: RapportageType,
  isMilieugegevensValidatie: boolean,
  isTeeltplanRegelsValidatie: boolean,
  relatie: Relatie[]
}

export enum RapportageType {
  Unknown = 0,
  DeelRapportage = 1,
  VolledigeRapportage = 2
}

export interface MetingInputDto {
  datum?: Date | null;
  periode: number;
  gehalte: number;
}

export interface MetingenInputDto {
  aantalMetingenPerJaar: number;
  nH4Metingen: MetingInputDto[];
  nO3Metingen: MetingInputDto[];
  pMetingen: MetingInputDto[];
  betrekkingTotTeeltwijze?: number;
  isBerekend: boolean;
}

export interface LozingsVormInputDto {
  betrekkingTotTeeltwijze: number;
  value: number;
}

export interface Relatie {
  isVolledig: boolean;
  redenVanAfkeuring: string;
  uoNummer: string;
  pandId: string;
  registratiejaar: string;
  postAdresStraat: string;
  postAdresHuisnummer: string;
  postAdresHuisnummerToevoeging: string;
  postAdresPostCode: string;
  bedrijfsnaam: string;
  postAdresPlaats: string;
  contactpersoon: string;
  telefoon: string;
  internet: string;
  fax: string;
  email: string;
  vestigingsAdresStraat: string;
  vestigingsAdresHuisnummer: string;
  vestigingsAdresPostCode: string;
  vestigingsAdresPlaats: string;
  vestigingsAdresGemeente: string;
  gemeente: string;
  waterschap: string;
  teeltwijze: number;
  kvkNummer: string;
  controle: {
    zandfilterDrainage: boolean;
    incidenteleLozing: boolean;
    calamiteit: boolean;
    waterOverschot: boolean;
    drainageLozing: boolean;
  };
  lozingsVorm: LozingsVormInputDto[];
  waterLozingSituatie: number;
  verbruiksGegevens: {
    verbruikN: number;
    verbruikP: number;
  };
  lozingen: {
    lozingen: {
      periode: number;
      hoeveelheid: number;
    }[];
    betrekkingTotTeeltwijze?: number;
    isBerekend?: boolean;
    berekendeWaarde?: {
      hoeveelheid: number;
    }
  }[];
  metingen: MetingenInputDto[];
  teeltplan: {
    teeltplanRegel: {
      gewasCode: string;
      gewas: string;
      clusterCode: number;
      eindDatumGeldigheid: Date;
      beginDatumGeldigheid: Date;
      oppervlakte: number;
      teeltInGrond: boolean;
      teeltInGrondMetAssimilatieBelichting: boolean;
    }[];
  };
  verzendBevestiging: {
    opmerking: string;
    naam: string;
    datum: string;
    plaats: string;
    isHandtekeningAanwezig: boolean;
    deskundige: boolean;
    deskundigeNaam: string;
  };
};