import Logo from '@/assets/logo.jpg';
import { UserAvatar } from '@/lib/components/core/auth/userAvatar';
import { Roles, tokenContainsRole, useUserContext } from '@/lib/context/userContext';
import { BookOutlined, DownOutlined, InfoCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Layout, Select, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Feature } from '../features/feature';
import styles from './core.module.scss';
import { useNavigate } from 'react-router-dom';
import { useGetAll, useGetDeelnemers } from '@/lib/queries/content';
import { useFeature } from '@/lib/hooks/useFeature';
import { DeelnemerOutput } from '@/lib/types/deelnemer';
import { PaginationQueryParameters } from '@/lib/types';
const { Header } = Layout;

interface MainHeaderProps {
  onLogin: () => void;
  onLogout: () => void;
  isAuthenticated: boolean;
}

interface OnBehalfProps {
  deelnemerId: string,
  deelnemerBedrijfsnaam: string
}

export const MainHeader: React.FunctionComponent<MainHeaderProps> = ({ onLogin, onLogout, isAuthenticated }) => {
  const { t } = useTranslation('core');
  const { username } = useUserContext();
  const navigate = useNavigate();
  const showEditButton = tokenContainsRole([Roles.Administrator]);
  const showOnBehalf = tokenContainsRole([Roles.Administrator]) && useFeature('show-on-behalf')
  const showGuideManagementButton = useFeature("show-handleiding-button");

  const [onBehalfFilterState, setOnBehalfFilterState] = useState<PaginationQueryParameters<DeelnemerOutput>>({
    page: 1,
    pageSize: 999
  });
  const [selectedOnBehalf, setSelectedOnBehalf] = useState<OnBehalfProps>({ deelnemerId: localStorage.getItem('selectedDeelnemerId') ?? '', deelnemerBedrijfsnaam: localStorage.getItem('selectedDeelnemerBedrijfsnaam') ?? '' })

  const { data: pdfList } = useGetAll();
  const { data: onBehalfDeelnemerData, isLoading: onBehalfDeelnemerDataIsLoading, isError: onBehalfDeelnemerIsError } = useGetDeelnemers();

  const onPrivacy = () => {
    navigate('/privacy');
  };

  const onManual = async (pdf) => {
    try {
      const base64String = pdf.file.includes(",") ? pdf.file.split(",")[1] : pdf.file;

      fetch(`data:application/pdf;base64,${base64String}`)
        .then((res) => res.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdf.name.endsWith(".pdf") ? pdf.name : `${pdf.name}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error fetching and downloading PDF:", error));
    } catch (error) {
      console.error("Error processing Base64 PDF:", error);
    }
  };

  const guideMenuItems = [
    ...(showEditButton && showGuideManagementButton ? [
      {
        key: "extra-button",
        label: t('userMenu.guide-management'),
        icon: <SettingOutlined />,
        onClick: () => {
          navigate('/content-management?tab=guide')
        },
      },
      {
        key: "divider",
        type: "divider" as const
      }
    ] : []),
    ...(pdfList
      ? pdfList.length > 0
        ? pdfList.map((pdf, index) => ({
          key: `guide-${index}`,
          label: pdf.name,
          onClick: () => onManual(pdf),
        }))
        : [{ key: "empty", label: t("userMenu.noGuides"), disabled: true }]
      : [
        {
          key: "loading",
          label: (
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <Spin size="small" /> {t("userMenu.loading")}
            </div>
          ),
          disabled: true,
        },
      ]),
  ];

  const menuItems = [
    {
      key: 1,
      label: t('userMenu.privacy'),
      icon: <InfoCircleOutlined />,
      onClick: () => navigate("/privacy")
    },
    {
      key: 2,
      label: t('userMenu.guides'),
      icon: <BookOutlined />,
      children: guideMenuItems,
    },
    {
      key: 3,
      label: t('userMenu.logout'),
      icon: <UserOutlined />,
      onClick: onLogout,
    },
  ];

  const handleSearch = (value: string) => {
    setOnBehalfFilterState(() => ({
      filterValues: {
        bedrijfsnaam: value ? [value] : undefined
      },
      page: 1,
      pageSize: 9990
    }));
  };

  const handleSelect = (option: any) => {
    const { value, label } = option;
    localStorage.setItem('selectedDeelnemerId', value)
    localStorage.setItem('selectedDeelnemerBedrijfsnaam', label)
    setSelectedOnBehalf({ deelnemerId: value, deelnemerBedrijfsnaam: label });
  }

  const handleClear = () => {
    localStorage.removeItem('selectedDeelnemerId')
    localStorage.removeItem('selectedDeelnemerBedrijfsnaam')
    setSelectedOnBehalf({ deelnemerId: '', deelnemerBedrijfsnaam: '' });
  }

  return (
    <Header className={styles.header}>
      <div className={styles.logo}>
        <img src={Logo} alt="Logo" />
      </div>
      {isAuthenticated ? (
        <Space>
          {showOnBehalf && (
            <>
              <>
                Handelen namens:
              </>
              <Select
                showSearch
                allowClear
                style={{ width: 250 }}
                placeholder="Teler selecteren"
                loading={onBehalfDeelnemerDataIsLoading}
                onSearch={handleSearch}
                onSelect={handleSelect}
                labelInValue
                value={selectedOnBehalf.deelnemerId ? { value: selectedOnBehalf.deelnemerId, label: selectedOnBehalf.deelnemerBedrijfsnaam } : undefined}
                onClear={handleClear}
                filterOption={false}
              >
                {(onBehalfDeelnemerData?.data ?? [] as DeelnemerOutput[])
                  .map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.bedrijfsnaam}
                    </Select.Option>
                  ))}
              </Select>
            </>
          )}
          <UserAvatar
            user={username}
            items={menuItems}
          />
        </Space>
      ) : (
        <div>
          <Button type="default" style={{ marginRight: 10 }} onClick={onPrivacy}>
            {t('userMenu.privacy')}
          </Button>
          <Dropdown menu={{ items: guideMenuItems }} trigger={['click']}>
            <Button style={{ marginRight: 10 }}>
              {t('userMenu.guides')} <DownOutlined />
            </Button>
          </Dropdown>
          <Feature flag="show-maintenance-mode">
            <Button type="primary" onClick={onLogin}>
              {t('userMenu.login')}
            </Button>
          </Feature>
        </div>
      )}
    </Header>
  );
};