import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Polygon, TileLayer, Tooltip, useMapEvent, useMapEvents } from 'react-leaflet'
import { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css"
import { Button, Drawer, Form, Input, message, Spin } from "antd";
import { useGetPanden, useGetVerblijfsobjecten } from "@/lib/queries/manual_input";
import L, { LatLngBounds } from "leaflet";
import { Pand, Verblijfsobject } from "@/lib/types/bag";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { Relatie } from "@/lib/types";
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

interface BagMapProps {
  modalIsShown: boolean;
  setModalIsShown: (visible: boolean) => void;
}

export const BagMap: React.FunctionComponent<BagMapProps> = ({ modalIsShown, setModalIsShown }) => {
  const MIN_ZOOM_FOR_FETCH = 18;
  const { t } = useTranslation('core');

  const mapRef = useRef<any>(null);

  const [messageApi, contextHolder] = message.useMessage();

  const [selectedVerblijfsobject, setSelectedVerblijfsobject] = useState<Verblijfsobject | null>(null);
  const [selectedPand, setSelectedPand] = useState<Pand | null>(null);
  const [currentBounds, setCurrentBounds] = useState<LatLngBounds | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number>(8);
  const [statusMessageIsOpened, setStatusMessageIsOpenend] = useState<boolean>(false);

  const [isSelectingPand, setIsSelectingPand] = useState<boolean>(false);
  const [isSelectingAdres, setIsSelectingAdres] = useState<boolean>(true);
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false);

  const form = useFormInstance<Relatie>();

  const { data: verblijfsobjecten, isLoading: isBedrijfsobjectenLoading, error: verblijfsObjectenError } = useGetVerblijfsobjecten(currentBounds, currentZoom >= MIN_ZOOM_FOR_FETCH && isSelectingAdres);
  const { data: panden, isLoading: isPandenLoading, error: pandenError } = useGetPanden(currentBounds, currentZoom >= MIN_ZOOM_FOR_FETCH && isSelectingPand);

  useEffect(() => {
    if (!isBedrijfsobjectenLoading) {
      messageApi.destroy();
      setStatusMessageIsOpenend(false);
    } else {
      messageApi.open({
        type: 'loading',
        content: `Panden aan het laden...`,
        duration: 0,
      });
      setStatusMessageIsOpenend(true);
    }
  }, [isBedrijfsobjectenLoading, verblijfsobjecten])

  useEffect(() => {
    if (verblijfsObjectenError) {
      messageApi.open({
        type: 'error',
        content: `Er is iets mis gegaan met het ophalen van gegevens bij BAG. Neem contact op met de servicedesk.`,
        duration: 5000,
      });
    }
  }, [verblijfsObjectenError])

  useEffect(() => {
    if (modalIsShown) {
      setIsSelectingAdres(true);
      setTimeout(() => {
        mapRef.current.invalidateSize();
        mapRef.current.locate();
        setCurrentBounds(mapRef.current.getBounds());
      }, 0);
    }
  }, [modalIsShown]);

  function MapEventHandlers() {
    const mapEvent = useMapEvents({
      locationfound(e) {
        mapEvent.flyTo(e.latlng, MIN_ZOOM_FOR_FETCH)
      },
    })

    // can do checks here

    const zoomEvent = useMapEvent("zoomend", () => {
      setCurrentZoom(zoomEvent.getZoom());

      if (zoomEvent.getZoom() < MIN_ZOOM_FOR_FETCH && !isBedrijfsobjectenLoading && !statusMessageIsOpened) {
        messageApi.open({
          type: 'info',
          content: `Zoom verder in om een pand te selecteren`,
          duration: 0,
        });
        setStatusMessageIsOpenend(true);
      }
    })

    const moveendEvent = useMapEvent("moveend", () => {
      setCurrentBounds(moveendEvent.getBounds());
    })


    return null;
  }

  function handleSetPandID() {
    form.setFieldValue('pandId', selectedPand?.identificatie)
    form.setFieldValue('postAdresPostCode', selectedVerblijfsobject?.postcode)
    form.setFieldValue('postAdresStraat', selectedVerblijfsobject?.straatnaam)
    form.setFieldValue('postAdresPlaats', selectedVerblijfsobject?.woonplaats)
    form.setFieldValue('postAdresHuisnummer', selectedVerblijfsobject?.huisnummer)
    form.setFieldValue('postAdresHuisnummerToevoeging', selectedVerblijfsobject?.huisletters)
  }

  return (
    <>
      {contextHolder}
      <MapContainer ref={mapRef} style={{ height: `${window.innerHeight - 450}px`, width: '100%' }} center={[52.0907, 5.1214]} zoom={8} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MapEventHandlers />

        {!isBedrijfsobjectenLoading && currentZoom >= MIN_ZOOM_FOR_FETCH && isSelectingAdres && verblijfsobjecten && verblijfsobjecten.map((verblijfsobject) => (
          <Marker
            key={verblijfsobject.id}
            position={verblijfsobject.positionCoordinates}
            eventHandlers={{ click: () => { setSelectedVerblijfsobject(verblijfsobject); setIsShowingDetails(true) } }}
            icon={L.icon({
              iconUrl: markerIcon,
              shadowUrl: markerShadow,
              iconSize: [17.5, 28.7],
              iconAnchor: [8.4, 28.7],
              popupAnchor: [0.7, -23.8],
              shadowSize: [28.7, 28.7]
            })}
          >
            <Tooltip>
              <strong>{verblijfsobject.straatnaam} {verblijfsobject.huisnummer} {verblijfsobject.huisletters}</strong>
              <p>{verblijfsobject.postcode}, {verblijfsobject.woonplaats}</p>
            </Tooltip>
          </Marker>
        ))}

        {!isPandenLoading && currentZoom >= MIN_ZOOM_FOR_FETCH && !isShowingDetails && isSelectingPand && panden && panden.map((pand) => (
          <Polygon
            key={pand.id}
            positions={pand.polygonCoordinates}
            eventHandlers={{
              click: () => {
                setSelectedPand(pand);
                setIsSelectingPand(false);
                setIsShowingDetails(true);
              },
            }}
          >
            <Tooltip>
              <strong>Pand ID: {pand.identificatie}</strong>
              <p>Bouwjaar: {pand.bouwjaar}</p>
              <p>Status: {pand.status}</p>
              <p>Gebruik: {pand.gebruiksdoel}</p>
              <p>Oppervlakte: {pand.oppervlakteMin} m²</p>
            </Tooltip>
          </Polygon>
        ))}
      </MapContainer>

      <Drawer
        title="Pand gegevens"
        placement="right"
        width={400}
        onClose={() => { setSelectedVerblijfsobject(null); setIsShowingDetails(false); setIsSelectingAdres(true); setIsSelectingPand(false); setSelectedPand(null) }}
        open={isShowingDetails}
      >
        <div>
          {!!isBedrijfsobjectenLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <Form layout="vertical">

                <Form.Item label="Straatnaam">
                  <Input value={selectedVerblijfsobject?.straatnaam} readOnly />
                </Form.Item>
                <Form.Item label="Huisnummer">
                  <Input value={selectedVerblijfsobject?.huisnummer} readOnly />
                </Form.Item>
                <Form.Item label="Huisletters">
                  <Input value={selectedVerblijfsobject?.huisletters} readOnly />
                </Form.Item>
                <Form.Item label="Postcode">
                  <Input value={selectedVerblijfsobject?.postcode} readOnly />
                </Form.Item>
                <Form.Item label="Woonplaats">
                  <Input value={selectedVerblijfsobject?.woonplaats} readOnly />
                </Form.Item>
                <Form.Item label="Pand Identificatie">
                  {selectedPand?.identificatie ? (
                    <Input value={selectedPand?.identificatie} readOnly />
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsShowingDetails(false);
                        setIsSelectingPand(true);
                        setIsSelectingAdres(false);
                      }}
                    >
                      Selecteer pand
                    </Button>
                  )}
                </Form.Item>

                <Button
                  type="primary"
                  disabled={!selectedPand}
                  onClick={() => {
                    handleSetPandID()
                    setIsSelectingPand(false);
                    setIsSelectingAdres(true);
                    setIsShowingDetails(false);
                    setSelectedVerblijfsobject(null);
                    setSelectedPand(null);
                    setModalIsShown(false);
                  }}>
                  Koppel mijn bedrijf
                </Button>
              </Form>

            </>
          )}
        </div>
      </Drawer>
    </>
  )
}; 