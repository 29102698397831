import { Button, Checkbox, Col, Flex, Form, Input, InputNumber, Modal, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from '../../page.module.scss';
import { BagMap } from '@/lib/components/bag/bagMap';
import { useState } from 'react';
import { useFeature } from '@/lib/hooks/useFeature';
import { useForm } from 'antd/es/form/Form';
import { Relatie } from '@/lib/types';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';

export const Bedrijfsgegevens: React.FunctionComponent = () => {
  const { t } = useTranslation('input');
  const form = useFormInstance<Relatie>();
  const [modalIsShown, setModalIsShown] = useState(false);
  const isBagEnabled = useFeature('show-bag-koppeling');

  return (
    <>
      <Row className={styles.rowWithoutBorder}>
        <Col className={styles.blueTextLeft} span={3}>
          {t('form.companyInfo.uonumber')}
        </Col>
        <Col span={3}>
          <Form.Item name={'uoNummer'}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.postalAddress')}
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'postAdresStraat'}>
            <Input />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={1}>
          {t('form.companyInfo.houseNumber')}
        </Col>
        <Col span={1}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'postAdresHuisnummer'}>
            <Input />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={2}>
          {t('form.companyInfo.addition')}
        </Col>
        <Col span={1}>
          <Form.Item name={'postAdresHuisnummerToevoeging'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col className={styles.blueTextLeft} span={3}>
          {t('form.companyInfo.kvkNumber')}
        </Col>
        <Col span={3}>
          <Form.Item
            required
            hasFeedback
            name="kvkNummer"
            rules={[
              {
                required: true,
                message: 'Het KVK-nummer is verplicht.',
              },
              {
                pattern: /^\d{8}$/,
                message: 'Het KVK-nummer moet precies 8 cijfers bevatten.',
              },
            ]}
          >
            <Input maxLength={8} />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.zipcode')}
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'postAdresPostCode'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col span={6} />
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.city')}
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'postAdresPlaats'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col className={styles.blueTextLeft} span={3}>
          {t('form.companyInfo.companyName')}
        </Col>
        <Col span={3}>
          <Form.Item name={'bedrijfsnaam'}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.phoneNumber')}
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'telefoon'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col className={styles.blueTextLeft} span={3}>
          {t('form.companyInfo.contactPerson')}
        </Col>
        <Col span={3}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'contactpersoon'}>
            <Input />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.url')}
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: false, message: '' }]} name={'internet'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col span={6} />
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.fax')}
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'fax'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col className={styles.blueTextLeft} span={3}>
          {t('form.companyInfo.vestigingsAdresStraat')}
        </Col>
        <Col span={3}>
          <Form.Item name={'vestigingsAdresStraat'}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.email')}
        </Col>
        <Col span={4}>
          <Form.Item rules={[{ required: true, message: '' }]} name={'email'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col className={styles.blueTextLeft} span={3}>
          {t('form.companyInfo.vestigingsAdresPostCode')}
        </Col>
        <Col span={3}>
          <Form.Item name={'vestigingsAdresPostCode'}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.localAuthority')}
        </Col>
        <Col span={4}>
          <Form.Item name={'gemeente'}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.rowWithoutBorder}>
        <Col className={styles.blueTextLeft} span={3}>
          {t('form.companyInfo.plaats')}
        </Col>
        <Col span={3}>
          <Form.Item name={'vestigingsAdresPlaats'}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col className={styles.blueTextRight} span={3}>
          {t('form.companyInfo.localAuthorityWater')}
        </Col>
        <Col span={4}>
          <Form.Item name={'waterschap'}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      {isBagEnabled && (
        <>
          <Row className={styles.rowWithoutBorder}>
            <Col className={styles.blueTextLeft} span={3}>
              {t('form.companyInfo.bagKoppeling')}
            </Col>
            <Col span={3}>
              <Form.Item name={"pandId"} rules={[{ required: true, message: "Koppel je bedrijf aan een pand." }]}>
                {form.getFieldValue("pandId") ? (
                  <Input value={form.getFieldValue("pandId")} style={{ width: "100%" }} readOnly />
                ) : (
                  <Button type="default" style={{ width: "100%" }} onClick={() => setModalIsShown(true)}>
                    Pand koppelen
                  </Button>
                )}
              </Form.Item>
            </Col>
            {form.getFieldValue("pandId") && (
              <Col>
                <Button shape="circle" icon={<EditOutlined />} style={{ marginLeft: '10px' }} onClick={() => setModalIsShown(true)} />
              </Col>
            )}
          </Row>

          <Modal
            title={"Bedrijf koppelen aan pand"}
            open={modalIsShown}
            onCancel={() => setModalIsShown(false)}
            width={'80%'}
            footer={null}
          >
            <BagMap modalIsShown={modalIsShown} setModalIsShown={setModalIsShown} />
          </Modal>
        </>
      )}
    </>
  );
};