import { RequireLogin } from '@/lib';
import { JSX } from 'react';
import { Outlet } from 'react-router-dom';

export const AuthLayout = (): JSX.Element => {
  return (
    <RequireLogin>
      <Outlet />
    </RequireLogin>
  );
};