import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { createFilterParams, fetchAuthenticatedForJson, fetchWithoutAuth } from '../utils';
import { Artikel, Handleiding } from '../types/content';
import { DeelnemerOutput } from '../types/deelnemer';
import { PagedResponse, PaginationQueryParameters } from '../types';

const QUERY_KEY = ['config'];
const BASE_URL = import.meta.env.VITE_CONFIG_API_URL;

export const useGetArtikels = (): UseQueryResult<Artikel[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'artikels'],
    queryFn: () => fetchWithoutAuth<Artikel[]>(`${BASE_URL}/artikels`),
  });

export const useGetDeelnemers = (params?: PaginationQueryParameters<DeelnemerOutput>): UseQueryResult<PagedResponse<DeelnemerOutput>, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'deelnemers', params],
    queryFn: () => fetchAuthenticatedForJson<DeelnemerOutput[]>(`${BASE_URL}/deelnemers?${createFilterParams(params ?? {})}`),
    enabled: !!params
  });

export const useGetArtikelById = (id?: number): UseQueryResult<Artikel, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticatedForJson<Artikel>(`${BASE_URL}/artikel/${id}`),
    enabled: !!id,
  });

export const useCreateArtikel = () => {
  return useMutation<Artikel, Error, Artikel>({
    mutationFn: (newArtikel: Artikel) =>
      fetchAuthenticatedForJson<Artikel>(`${BASE_URL}/artikel`, {
        json: newArtikel,
        method: 'POST'
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useUpdateArtikel = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<Artikel, Error, Artikel>({
    mutationFn: (newArtikel: Artikel) =>
      fetchAuthenticatedForJson<Artikel>(`${BASE_URL}/artikel/${id}`, {
        json: newArtikel,
        method: 'PUT',
      }),
    mutationKey: [...QUERY_KEY, id],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY, id] });
    },
  });
};

export const useDeleteArtikel = () => {
  return useMutation<Artikel, Error, number>({
    mutationFn: (id: number) =>
      fetchAuthenticatedForJson<Artikel>(`${BASE_URL}/artikel/${id}`, {
        method: 'DELETE'
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useCreateHandleiding = () => {
  return useMutation<Handleiding, Error, Handleiding>({
    mutationFn: (newHandleiding: Handleiding) => {
      return fetchAuthenticatedForJson<Handleiding>(`${BASE_URL}/handleiding`, {
        json: newHandleiding,
        method: "POST"
      });
    },
    mutationKey: [...QUERY_KEY],

  });
}

export const useGetCurrentHandleiding = (): UseQueryResult<Handleiding> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'handleiding'],
    queryFn: () => fetchAuthenticatedForJson<Handleiding>(`${BASE_URL}/handleiding`),
  });

export const useGetAll = (): UseQueryResult<Handleiding[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'handleidingen'],
    queryFn: async () => {
      var data = await fetchWithoutAuth<Handleiding[]>(`${BASE_URL}/handleidingen`);
      return data.sort((a, b) => new Date(b.datum).getTime() - new Date(a.datum).getTime());;
    }
  });