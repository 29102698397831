import proj4 from "proj4"
import { Pand, PandDTO, Verblijfsobject, VerblijfsobjectDTO } from "../types/bag";
import { LatLng, LatLngBounds } from "leaflet";

proj4.defs("EPSG:28992", "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.331,465.552,-0.398957,0.343988,-1.877402,4.0725 +units=m +no_defs");

export async function fetchBagPanden(url: string, bounds: LatLngBounds): Promise<Pand[]> {
  const sw = proj4("EPSG:4326", "EPSG:28992", [bounds.getSouthWest().lng, bounds.getSouthWest().lat]);
  const ne = proj4("EPSG:4326", "EPSG:28992", [bounds.getNorthEast().lng, bounds.getNorthEast().lat]);

  url += `&bbox=${sw[0]},${sw[1]},${ne[0]},${ne[1]}`;

  const response = await fetch(url);
  const xmlText = await response.text();
  const data = parsePanden(xmlText);
  return data.map(mapPandDtoToModel);
}

export async function fetchBagVerblijfsobjecten(url: string, bounds: LatLngBounds): Promise<Verblijfsobject[]> {
  const sw = proj4("EPSG:4326", "EPSG:28992", [bounds.getSouthWest().lng, bounds.getSouthWest().lat]);
  const ne = proj4("EPSG:4326", "EPSG:28992", [bounds.getNorthEast().lng, bounds.getNorthEast().lat]);

  url += `&bbox=${sw[0]},${sw[1]},${ne[0]},${ne[1]}`;

  const response = await fetch(url);
  const xmlText = await response.text();
  const data = parseVerblijfsobjecten(xmlText);
  return data.map(mapDtoToModel);
}

export function mapPandDtoToModel(dto: PandDTO): Pand {
  const convertRDToWGS84 = useProjection();

  const polygonCoordinates = dto.polygonCoordinates.map(ring =>
    ring.map(coord => {
      const converted = convertRDToWGS84(coord[0], coord[1]);
      return new LatLng(converted[0], converted[1]);
    })
  );

  const convertedPosition = convertRDToWGS84(dto.positionCoordinates[0], dto.positionCoordinates[1]);

  return {
    id: dto.id,
    identificatie: dto.identificatie,
    bouwjaar: dto.bouwjaar,
    status: dto.status,
    gebruiksdoel: dto.gebruiksdoel,
    oppervlakteMin: dto.oppervlakteMin,
    oppervlakteMax: dto.oppervlakteMax,
    aantalVerblijfsobjecten: dto.aantalVerblijfsobjecten,
    fuuid: dto.fuuid,
    positionCoordinates: new LatLng(convertedPosition[0], convertedPosition[1]),
    polygonCoordinates
  };
}


function mapDtoToModel(dto: VerblijfsobjectDTO): Verblijfsobject {
  const convertRDToWGS84 = useProjection();
  const convertedCoordinates = convertRDToWGS84(dto.coordinates[0], dto.coordinates[1]);

  return {
    id: dto.id,
    identificatie: dto.identificatie,
    pandidentificatie: undefined,
    straatnaam: dto.openbareRuimte,
    huisnummer: dto.huisnummer || "",
    huisletters: dto.huisletter || "",
    postcode: dto.postcode,
    woonplaats: dto.woonplaats,
    positionCoordinates: new LatLng(convertedCoordinates[0], convertedCoordinates[1]),
    polygonCoordinates: []
  }
}


export function useProjection() {
  return (x: number, y: number) => {
    const [lon, lat] = proj4("EPSG:28992", "EPSG:4326", [x, y]);
    return [lat, lon]; // Convert to Leaflet format
  };
}

export function parsePanden(xmlText: string): PandDTO[] {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlText, "application/xml");

  const pandNodes = xmlDoc.getElementsByTagName("bag:pand");
  const panden: PandDTO[] = [];

  for (let i = 0; i < pandNodes.length; i++) {
    const node = pandNodes[i];

    const getText = (tagName: string) => {
      const el = node.getElementsByTagName(tagName)[0];
      return el?.textContent?.trim() ?? null;
    };

    // Extract polygon coordinates from gml:posList
    const posListNode = node.getElementsByTagName("gml:posList")[0]?.textContent?.trim();
    const polygonCoordinates: [number, number][][] = posListNode
      ? [posListNode.split(" ").map(Number).reduce((acc, val, idx, arr) => {
        if (idx % 2 === 0) acc.push([val, arr[idx + 1]]);
        return acc;
      }, [] as [number, number][])]
      : [[]];

    // Get the position as the first coordinate of the polygon
    const positionCoordinates: [number, number] = polygonCoordinates[0]?.[0] ?? [0, 0];

    const pand: PandDTO = {
      id: node.getAttribute("gml:id") ?? "",
      identificatie: getText("bag:identificatie") ?? "",
      bouwjaar: Number(getText("bag:bouwjaar") ?? 0),
      status: getText("bag:status") ?? "",
      gebruiksdoel: getText("bag:gebruiksdoel") ?? "",
      oppervlakteMin: Number(getText("bag:oppervlakte_min") ?? 0),
      oppervlakteMax: Number(getText("bag:oppervlakte_max") ?? 0),
      aantalVerblijfsobjecten: Number(getText("bag:aantal_verblijfsobjecten") ?? 0),
      fuuid: getText("bag:fuuid") ?? "",
      positionCoordinates,
      polygonCoordinates
    };

    panden.push(pand);
  }

  return panden;
}

export function parseVerblijfsobjecten(xmlText: string): VerblijfsobjectDTO[] {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlText, "application/xml");

  const verblijfsobjectNodes = xmlDoc.getElementsByTagName("bag:verblijfsobject");
  const verblijfsobjecten: VerblijfsobjectDTO[] = [];

  for (let i = 0; i < verblijfsobjectNodes.length; i++) {
    const node = verblijfsobjectNodes[i];

    const getText = (tagName: string) => {
      const el = node.getElementsByTagName(tagName)[0];
      return el?.textContent?.trim() ?? null;
    };

    const geomNode = node.getElementsByTagName("gml:pos")[0]?.textContent?.trim();
    const coordinates: [number, number] = geomNode
      ? (geomNode.split(" ").map(Number) as [number, number])
      : [0, 0];

    const verblijfsobject: VerblijfsobjectDTO = {
      id: node.getAttribute("gml:id") ?? "",
      identificatie: getText("bag:identificatie") ?? "",
      rdf_seealso: getText("bag:rdf_seealso") ?? "",
      oppervlakte: Number(getText("bag:oppervlakte") ?? 0),
      status: getText("bag:status") ?? "",
      gebruiksdoel: getText("bag:gebruiksdoel") ?? "",
      openbareRuimte: getText("bag:openbare_ruimte") ?? "",
      huisnummer: getText("bag:huisnummer") ? getText("bag:huisnummer") : null,
      huisletter: getText("bag:huisletter"),
      toevoeging: getText("bag:toevoeging"),
      postcode: getText("bag:postcode") ?? "",
      woonplaats: getText("bag:woonplaats") ?? "",
      bouwjaar: Number(getText("bag:bouwjaar") ?? 0),
      pandIdentificatie: getText("bag:pandidentificatie") ?? "",
      pandStatus: getText("bag:pandstatus") ?? "",
      coordinates,
      verblijfsobject: undefined
    };

    verblijfsobjecten.push(verblijfsobject);
  }

  return verblijfsobjecten;
}