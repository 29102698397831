import { Button, Col, Form, Row, Space } from 'antd';
import { VerzendBevestiging } from './components/verzendBevestiging';
import dayjs, { Dayjs } from 'dayjs';
import { RapportageType } from '@/lib/types';
import { Feature } from '@/lib/components/core/features/feature';

export const Step5: React.FunctionComponent<{ onSubmit: (rapportageType: RapportageType) => void; isSaving: boolean }> = ({ onSubmit, isSaving }) => {
  const form = Form.useFormInstance();
  const registratiejaar = form.getFieldValue('registratiejaar');
  const isDeelRapportage = form.getFieldValue('isDeelRapportage');

  const handleFullReportSubmit = () => onSubmit(RapportageType.VolledigeRapportage);
  const handlePartialReportSubmit = () => onSubmit(RapportageType.DeelRapportage);

  return (
    <>
      <VerzendBevestiging />
      <Space>
        {!isDeelRapportage ? (
          <Button loading={isSaving} disabled={isSaving} onClick={handleFullReportSubmit} type="primary" >
            Versturen als volledige rapportage
          </Button>
        ) : (
          <Button loading={isSaving} disabled={isSaving} onClick={handleFullReportSubmit} type="primary" >
            Versturen als deelrapportage
          </Button>
        )}
      </Space>
    </>
  );
};